<script>
// import MenuService from '@/service/menu.service';
// import { mapState } from 'vuex';
import MenuItemService from '@/service/menu-item.service';
import { MenuItemGroup } from '@/utils/enums/MenuItemGroup';

export default {
  name: 'list-menu-menu-item',

  components: {
    ModalNewMenuItem: () => import('./ModalNewMenuItem.vue'),
  },

  data() {
    return {
      loading: false,
      form: {},
      options: {
        sortBy: ['name'],
        sortDesc: [false],
        search: '',
      },

      menuItemGroup: MenuItemGroup,
      totalItems: 0,
      items: [],
      headers: [
        { text: 'Nome', value: 'name' },
        { text: 'Grupo', value: 'group' },
        { text: 'Valor Calorico (100g)', value: 'calorific_value' },
        { text: 'Proteínas (100g)', value: 'protein' },
        { text: 'Ações', align: 'center', value: 'actions' },
      ],
    };
  },

  watch: {
    options: {
      handler() {
        this.fetchMeuItem();
      },

      deep: true,
    },
  },

  methods: {
    async fetchMeuItem() {
      this.loading = true;
      const filter = {
        page: this.options.page,
        limit: this.options.itemsPerPage,
        search: this.options.search,
      };

      try {
        const { data, meta } = await MenuItemService.find(filter);
        this.items = data;
        this.page = meta?.currentPage;
        this.totalItems = meta?.totalItems;
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },

    preCreate() {
      this.$refs.modalNewMenuItem.$emit('create');
    },

    async edit(item) {
      this.$refs.modalNewMenuItem.$emit('edit', item.id);
    },

    async remove(payload) {
      this.$swal({
        text: `Deseja remover o item '${payload.name}'?`,
        icon: 'warning',
        confirmButtonText: 'SIM',
        showDenyButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return MenuItemService.delete(payload.id);
        },
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.$swal.fire({
              icon: 'success',
              title: 'Removido!',
              text: 'Item removido com sucesso!',
              timer: 2000,
              showConfirmButton: false,
            });
            this.fetchMeuItem();
          }
        })
        .catch(() => {
          this.$swal.close();
        });
    },
  },
};
</script>

<template>
  <div class="mt-10">
    <h3 class="pb-1 mb-2">Itens do Cardápio</h3>
    <v-card>
      <v-card flat>
        <v-card-text>
          <v-row class="mb-0" align="center">
            <v-col cols="2">
              <v-btn color="primary" width="100%" @click="preCreate">Novo Item</v-btn>
            </v-col>
            <v-col cols="4" offset="6">
              <v-text-field
                v-model="options.search"
                append-icon="mdi-magnify"
                label="Pesquisar por nome do item"
                color="white"
                hide-details="auto"
                outlined
                dense
              />
            </v-col>
          </v-row>

          <v-data-table
            :headers="headers"
            :items="items"
            :options.sync="options"
            :server-items-length="totalItems"
            :loading="loading"
            :footer-props="{ 'items-per-page-options': [10, 20, 30, 50] }"
            class="elevation-3 no-line-row"
          >
            <template v-slot:[`item.group`]="{ item }">
              {{ menuItemGroup[item.group] | empty }}
            </template>

            <template v-slot:[`item.calorific_value`]="{ item }">
              {{ item.calorific_value + ' cal' }}
            </template>

            <template v-slot:[`item.protein`]="{ item }">
              {{ item.protein + ' g' }}
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-btn icon title="Editar" @click="edit(item)">
                <v-icon small> mdi-pencil </v-icon>
              </v-btn>
              <v-btn icon color="error" title="Remover" @click="remove(item)">
                <v-icon small> mdi-delete </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-card>
    <modal-new-menu-item ref="modalNewMenuItem" @success="fetchMeuItem" />
  </div>
</template>
